<template>
    <a-layout>
        <a-layout-sider>
            <a-anchor class="side-nav" :affix="false" @click="handleClick">
                <a-anchor-link v-for="(item,key) in parts" :key="key" :href="item.keys[0]" :title="item.name" />
            </a-anchor>
        </a-layout-sider>
        <a-layout-content>
            <h1 class="h1">单位根检验结果</h1>
            <div class="result-area" v-if="modelInfo" id="modelInfo">
                <divider-title name="模型说明" />
                <div class="ant-table-wrapper">
                    <div class="ant-spin-nested-loading">
                        <div class="ant-spin-container">
                            <div class="ant-table ant-table-scroll-position-left ant-table-middle">
                                <div class="ant-table-content"><!---->
                                    <div class="ant-table-body">
                                        <table class="">
                                            <thead class="ant-table-thead">
                                                <tr>
                                                    <th :key="key" :style="{width:key===0?'100px':'auto'}" v-for="(item,key) in modelInfo[0]"><span class="ant-table-header-column"><div><span class="ant-table-column-title">{{item.val}}</span></div></span></th>
                                                </tr>
                                            </thead>
                                            <tbody class="ant-table-tbody">
                                                <tr class="ant-table-row ant-table-row-level-0" data-row-key="0" v-for="(item,key) in modelInfo" :key="key" v-show="key>0">
                                                    <td v-for="(i,k) in item" :key="k">{{i.val}}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="result-area" v-if="testResult" id="testResult">
                <divider-title name="检验结果" />
                <div class="ant-table-wrapper">
                    <div class="ant-spin-nested-loading">
                        <div class="ant-spin-container">
                            <div class="ant-table ant-table-scroll-position-left ant-table-middle">
                                <div class="ant-table-content">
                                    <div class="ant-table-body">
                                        <table class="">
                                            <thead class="ant-table-thead">
                                                <tr>
                                                    <th :key="key" v-for="(item,key) in testResult[0]"><span class="ant-table-header-column"><div><span class="ant-table-column-title">{{item.val}}</span></div></span></th>
                                                </tr>
                                            </thead>
                                            <tbody class="ant-table-tbody">
                                                <tr class="ant-table-row ant-table-row-level-0" data-row-key="0" v-for="(item,key) in testResult" :key="key" v-show="key>0">
                                                    <td v-for="(i,k) in item" :key="k">{{i.val}}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </a-layout-content>
    </a-layout>
</template>

<script>
import { getSessionItem } from '../../until/tools'
import DividerTitle from './components/DividerTitle.vue';
export default {
    components:{DividerTitle},
    computed:{
        result() {
            let data = getSessionItem('analysis_grangerCheck');
            return data.analysis_grangerCheck;
        },
        parts() {
            let arr = [
                {
                    name:'模型说明', 
                    keys:['modelInfo'],
                },
                {
                    name:'检验结果',
                    keys:['testResult'],
                },
            ]
            return arr;
        },
        modelInfo() {
            if(this.result) {
                return this.result.modelInfo.data;
            } else {
                return null;
            }
        },

        testResult() {
            if(this.result) {
                return this.result.testData.data;
            } else {
                return null;
            }
        },
    },
    methods:{
        handleClick(e,link) {
            e.preventDefault();
            var srcolls = document.getElementById(link.href);
            srcolls.scrollIntoView({block: 'start', behavior: 'smooth'});
        },
    }
}
</script>